<template>
    <div class="panel-map">
        <div id="map" class="panel-map__map"></div>
        <div class="panel-map__button-container">
            <button class="btn btn-primary" @click="closePopups">
                <img src="/img/icons/svg/codigo-limpo.svg" alt="limpar">
                <span>Limpar todos os detalhes</span>
            </button>
        </div>
        <div class="layer-switch">
            <select v-model="currentMapLayer">
                <option value="bing">Bing</option>
                <option value="google">Google</option>
            </select>
        </div>
    </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import BingMapPlugin from "@/utils/bingMapPlugin.js";
import { drawPolygons } from "@/utils/drawPolygons";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "PanelMap",
    data() {
        return {
            map: null,
            polygons: [],
            payload: {
                propertyId: "",
                cropId: ""
            },
            mapCenter: { latitude: -11.8831661, longitude: -60.1766577 },
            screen: 0,
            currentMapLayer: 'bing',
            bingLayer: null,
            googleLayer: null,
            mapUrls: {
                bing: {
                    url: `https://dev.virtualearth.net/REST/v1/Imagery/Metadata/Aerial?key=${process.env.VUE_APP_BING_MAPS_KEY}`,
                    attribution: "Tiles © Bing Maps"

                },
                google: {
                    url: "http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
                    attribution: "Tiles © Google",
                    subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
                },
            },
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getCropSelected"]),
        ...mapGetters("fields", ["allFields"]),
    },
    async mounted() {
        this.initializeMap();
        this.updateMap();
        this.updatePayload();
        await this.getFieldsByPropertyByCrop(this.payload);
    },
    watch: {
        async getCropSelected() {
            this.updatePayload();
            await this.getFieldsByPropertyByCrop(this.payload);
            this.updateMap();
        },
        currentMapLayer(newVal) {
            if (this.map) {
                this.updateMapLayer(newVal);
            }
        },
    },
    methods: {
        ...mapActions("fields", ["getFieldsByPropertyByCrop"]),
        updatePayload() {
            this.payload = {
                propertyId: this.getPropertySelected._id ? this.getPropertySelected._id : '',
                cropId: this.getCropSelected._id ? this.getCropSelected._id : ''
            }
        },
        initializeMap() {
            this.map = L.map('map', {
                center: [this.mapCenter.latitude, this.mapCenter.longitude],
                zoom: this.zoom,
                zoomControl: false
            }).setView([this.mapCenter.latitude, this.mapCenter.longitude], 12);;

            this.bingLayer = new BingMapPlugin({
                bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                imagerySet: 'Aerial',
            });

            this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                attribution: this.mapUrls.google.attribution,
                subdomains: this.mapUrls.google.subdomains,
                maxZoom: 19
            });

            this.updateMapLayer(this.currentMapLayer);

            L.control.zoom({ position: 'topleft' }).addTo(this.map);
        },



        updateMapLayer(newLayer) {
            if (!this.bingLayer || !this.googleLayer) {
                this.bingLayer = new BingMapPlugin({
                    bingMapsKey: process.env.VUE_APP_BING_MAPS_KEY,
                    imagerySet: 'Aerial',
                });

                this.googleLayer = L.tileLayer(this.mapUrls.google.url, {
                    attribution: this.mapUrls.google.attribution,
                    subdomains: this.mapUrls.google.subdomains,
                    maxZoom: 19
                });
            }

            if (this.map) {

                if (this.map.hasLayer(this.bingLayer)) {
                    this.map.removeLayer(this.bingLayer);
                }
                if (this.map.hasLayer(this.googleLayer)) {
                    this.map.removeLayer(this.googleLayer);
                }

                if (newLayer === "bing") {
                    this.map.addLayer(this.bingLayer);
                } else if (newLayer === "google") {
                    this.map.addLayer(this.googleLayer);
                }
            }
        },
        updateMap() {
            const fields = this.allFields || [];
            const crop = this.getCropSelected;
            this.polygons = drawPolygons(fields, crop);

            this.map.eachLayer(layer => {
                if (layer instanceof L.Polygon) {
                    this.map.removeLayer(layer);
                }
            });

            this.polygons.forEach(polygon => {
                const layer = L.polygon(polygon.latlngs, {
                    color: polygon.color,
                    fillColor: polygon.fillColor
                }).addTo(this.map);
                layer.bindPopup(polygon.text);
            });

            const coordinates = this.getPropertyCoordinates(
                this.getPropertySelected.coordinates.latitude,
                this.getPropertySelected.coordinates.longitude
            )

            if (!coordinates || !coordinates.latitude || !coordinates.longitude) {
                coordinates.latitude = this.mapCenter.latitude
                coordinates.longitude = this.mapCenter.longitude
                coordinates.zoom = 13
            }

            this.map.setView([coordinates.latitude, coordinates.longitude], 13);
        },

        closePopups() {
            this.map.eachLayer(layer => {
                if (layer instanceof L.Popup) {
                    layer.closePopup();
                }
            });
        },

    }
};
</script>

<style scoped lang="sass">
.panel-map
    height: 100%
    position: relative

    &__map
        height: 100%
        width: 100%

    &__button-container
        display: flex
        justify-content: center
        align-items: center
        flex-direction: row
        flex-wrap: nowrap
        position: absolute
        z-index: 400
        width: 100%
        height: 50px
        bottom: 24px

        img
            width: 25px
            height: 25px

.leaflet-verticalcenter
    position: absolute
    z-index: 1000
    pointer-events: none
    top: 50%
    /* possible because the placeholder's parent is the map */
    transform: translateY(-50%)
    /* using the CSS3 Transform technique */
    padding-top: 10px

.leaflet-verticalcenter .leaflet-control
    margin-bottom: 10px

.leaflet-control-zoom-in,
.leaflet-control-zoom-out
    float: right
    font: bold 18px "Lucida Console", Monaco, monospace
    text-indent: 1px

.leaflet-control-zoom-out
    font-size: 20px

.leaflet-touch .leaflet-control-zoom-in
    font-size: 22px

.leaflet-touch .leaflet-control-zoom-out
    font-size: 24px

.leaflet-top
    bottom: 0

.leaflet-top .leaflet-control-zoom
    top: 50%
    transform: translateY(-50%)

.layer-switch 
    position: absolute
    bottom: 10px
    left: 25px
    z-index: 1000
    background-color: white
    padding: 5px
    border-radius: 5px
</style>