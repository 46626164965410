<template>
    <div class="company-container">
        <div class="company-container__header">
            <span class="title">Logotipo</span>
        </div>
        <div v-if="!loading" class="content__item">
            <label class="app-label">Logotipo da empresa</label>
            <input
                ref="fileInput"
                type="file"
                class="app-input"
                @change="addFile"
            />
        </div>
        <div v-if="!loading" class="content__item">
            <img class="logo-media" :src="this.logotipo" alt="logotipo" />
        </div>  
        <div v-else-if="loading" class="no-content">
            <span class="spinner-border" />
        </div>     
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "Company",
    components: {
        
    },
    data() {
        return {
            logotipo: '',
            loading: false,
        };
    },
    computed: {
        ...mapGetters("property", ["getPropertySelected", "getProperties"]),
        ...mapGetters("users", ["profile", "logo"]),       
    },
    async mounted() {
        this.loading = true
        await this.getLogo(this.getPropertySelected.account._id)
        this.logo ? this.logotipo = this.logo : this.logotipo = 'https://firebasestorage.googleapis.com/v0/b/alvorecer-7db0e.appspot.com/o/logos%2FLOGO_AgroInteliColored.svg?alt=media&token=119e4ec0-80cc-4e34-9e2d-cfdafb9e203b'
        this.loading = false
    },
    methods: {
        ...mapActions("property", ["listProperty", "updateProperty"]),
        ...mapActions("users", ["getUser", "getLogo"]),
        ...mapActions("logos", ["upload"]),
        async addFile(event) {
            this.loading = true
            let formatData = new FormData();
            formatData.append("file", event.target.files[0]);
            formatData.append("accountId", this.getPropertySelected.account._id);
            formatData.append("userId", this.profile._id);
            await this.upload(formatData).then(async(response) => {
                if (response.status == 201) {
                    this.logotipo = response.data.data.logo
    
                    this.$nextTick(() => {
                        if (this.$refs.fileInput) {
                        this.$refs.fileInput.value = null;
                        }
                    });
                    await this.getLogo(this.getPropertySelected.account._id)
                    this.loading = false
                    return this.$vToastify.success(
                        "Salvo com sucesso.",
                        "Logotipo"
                    );
                } else {
                    this.loading = false
                    return this.$vToastify.error(
                        response.data.message,
                        "Logotipo"
                    );
                }
            });
        }
    },
    watch: {
        
    },
};
</script>

<style scoped>
input[type="file"] {
  padding: 5px;
}
</style>

<style scoped lang="sass">
.logo-media
    width: 250px
.company-container
    display: flex
    flex-direction: column
    grid-gap: 6px
    width: 100%

    &__header
        .title
            font-size: 24px
            color: $theme-color-primary

        .area-total
            font-size: 20px
            color: $theme-color-primary

        .actions
            display: flex
            justify-content: space-between

            .search-container
                display: flex
                position: relative

                &__input
                    width: 300px

                &__search
                    position: absolute
                    right: 8px
                    top: 8px
                    border: 0
                    background: none

                &__clear
                    position: absolute
                    right: 36px
                    top: 7px
                    border: 0
                    background: none

.company-table
    width: 100%

    &-header__actions
        width: 30%
        text-align: center

    &-content__actions
        display: flex
        grid-gap: 6px
        align-items: center
        justify-content: center

        .btn
            background: none

            &:hover
                background: $color-white-darken-1

            i
                color: $color-blue

.company-table th
    color: $color-blue
    padding: 0 10px
    font-size: 16px

.company-table tr
    border-radius: 20px
    height: 70px

.company-table td
    padding: 0 10px

.company-table tr:nth-child(even)
    background-color: #f2f2f2

.no-content
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    color: $color-grey
    grid-gap: 10px

@media (max-width: 430px)
    .company-container__header .actions .search-container__input
        width: 200px
</style>
