/* eslint-disable no-empty-pattern */
import Field from "../../api/Field";
import Ndvi from "../../api/Ndvi";
import Subarea from "../../api/Subarea";

const state = {
  myFields: [],
  fields: [],
  fieldSelected: {},
  imagesNDVI: [],
  photos: [],
  subAreas: [],
};

const getters = {
  myFields: (state) => state.myFields,
  allFields: (state) => state.fields,
  getFieldSelected: (state) => state.fieldSelected,
  getImagesNDVI: (state) => state.imagesNDVI,
  getPhotos: (state) => state.photos,
  getSubAreas: (state) => state.subAreas,
};

const actions = {
  async setMyFields({ commit }, myFields) {
    await commit("setFields", myFields);
  },

  getFields({ commit }) {
    Field.getAll().then((response) => {
      commit("setFields", response.data.data);
    });
  },

  async getField({ commit }, id) {
    await Field.get(id).then((response) => {
      commit("setFieldSelected", response.data.data);
    });
  },

  async getFieldsByProperty({ commit }, { propertyId, cropId }) {
    await Field.getFieldsByProperty(propertyId, cropId)
      .then((response) => {
        let filteredActiveFields = response.data.data.filter((field) => {
          return field.active == true
        })
        commit("setFields", filteredActiveFields);
      })
  },

  async getFieldsByPropertyByCrop({ commit }, payload) {
    const propertyId = payload.propertyId;
    const cropId = payload.cropId;
    await Field.getFieldsByPropertyByCrop(propertyId, cropId)
      .then((response) => {
        let filteredActiveFields = response.data.data.filter((field) => {
          return field.active == true
        })
        commit("setFields", filteredActiveFields);
      })
  },

  async getFieldsByPropertyByCropAndReturn({ commit }, payload) {
    const propertyId = payload.propertyId;
    const cropId = payload.cropId;
    let filteredActiveFields = []
    await Field.getFieldsByPropertyByCrop(propertyId, cropId)
    .then((response) => {
      filteredActiveFields = response.data.data.filter((field) => {
        return field.active == true
      })
    })
    return filteredActiveFields
  },

  async listFieldAllImagesNDVI({ commit }, id) {
    let images = [];
    await Field.getImagesNDVIField(id).then((response) => {
      commit("setImagesNDVI", response.data.data);
      images = response.data.data || [];
    });
    return images;
  },

  async listAllImagesNDVIByInterval({ commit }, payload) {
    let images = [];

    if (payload.interval && payload.interval.startDate) {
      await Ndvi.findByInterval(payload.interval).then((response => {
        commit("setImagesNDVI", response.data.data);
        images = response.data.data || [];
      }));
    } else {
      await Ndvi.getAll().then((response => {
        commit("setImagesNDVI", response.data.data);
        images = response.data.data || [];
      }));
    }
    return images;
  },

  async listFieldNdviImagesByInterval({ commit }, payload) {
    let images = [];
    if (payload.fieldId !== 'all') {
      await Field.getImagesNDVIByInterval(payload).then(
        (response) => {
          commit("setImagesNDVI", response.data.data);
          images = response.data.data || [];
        }
      );
    } else {
      await Field.getImagesNDVIByIntervalAllFields(payload).then((response) => {
        commit("setImagesNDVI", response.data.data);
        images = response.data.data || [];
      });
    }
    return images
  },

  clearNdvis({ commit }) {
    commit("setImagesNDVI", [])
  },

  listPropertyAllNdviImages({ commit }, id) {
    Field.getAllPropertyImagesNDVI(id).then((response) => {
      commit("setImagesNDVI", response.data.data);
    });
  },

  listPropertyNdviImagesByInterval({ commit }, payload) {
    Field.getPropertyImagesNDVIByInterval(
      payload.propertyId,
      payload.interval
    ).then((response) => {
      commit("setImagesNDVI", response.data.data);
    });
  },

  listPhotos({ commit }, id) {
    Field.getPhotos(id).then((response) => {
      commit("setPhotos", response.data.data);
    });
  },

  listPhotosByPropertie({ commit }, id) {
    Subarea.getPhotos(id).then((response) => {
      commit("setPhotos", response.data.data);
    });
  },

  listSubAreas({ commit }, id) {
    Field.getSubAreas(id).then((response) => {
      commit("setSubAreas", response.data.data);
    });
  },

    async createField({ commit }, field) {
        const response = await Field.createField(field);
        return response;
    },

    async updateField({ commit }, { fieldId, field }) {
        const response = await Field.updateField(fieldId, field);
        return response;
    },

  removeField({ commit, dispatch, rootGetters }, id) {
    Field.delete(id).then((response) => {
      commit("removeField", response.data.data);
      dispatch(
        "property/listFields",
        rootGetters["property/getPropertySelected"]._id,
        { root: true }
      );
    });
  },

  async postSubAreas({ }, subarea) {
    return await Field.postSubArea(subarea)
},

  async updateSubAreas({ }, subarea) {
    await Field.updateSubArea(subarea);
  },

  async removeSubAreas({ }, id) {
    await Field.deleteSubArea(id);
  },
};

const mutations = {
  setMyFields: (state, myFields) => (state.myFields = myFields),
  setFields: (state, fields) => (state.fields = fields),
  setFieldSelected: (state, fieldSelected) =>
    (state.fieldSelected = fieldSelected),
  newField: (state, field) => {
    field.subarea = []
    state.fields.unshift(field)
  },
  updateField: (state, changedField) => {
    const index = state.fields.findIndex(
      (elem) => elem._id == changedField._id
    );
    if (index !== -1) state.fields.splice(index, 1, changedField);
  },
  setImagesNDVI: (state, imagesNDVI) => {
    state.imagesNDVI = imagesNDVI.filter((res) => res.active == true);
  },
  setPhotos: (state, photos) => {
    state.photos = photos.filter((res) => res.active == true);
  },
  setSubAreas: (state, subAreas) => {
    state.subAreas = subAreas.filter((res) => res.active == true);
  },
  removeField: (state, field) =>
    (state.fields = state.fields.filter((elem) => elem._id != field._id)),
};

export default { state, getters, actions, mutations, namespaced: true };
