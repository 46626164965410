import Logo from '../../api/Logo'

const state = {
    logo: '',
}

const getters = {
    logo: state => state.logo,
}

const actions = {
    async upload({ commit }, file) {
        const response = await Logo.updateLogo(file)
        return response
    },
}

const mutations = {
    setLogo: (state, logo) => state.logo = logo,
}

export default {state, getters, actions, mutations, namespaced: true}
