import API from "./API";

export default {
  getAccountToProperty(id) {
    return API.get(`properties/${id}`);
  },
  getLogoToAccount(id) {
    return API.get(`accounts/${id}`);
  },
  updateLogo(file) {
    return API.post(`accounts/image`, file);
  },
};
